<template>
    <div class="app-main__inner">
        <div class="container mb-3">
            <div class="row">
                <div class="col-md-12 bg-white p-3">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="md:px-20 mt-10" v-if="GET_LOCALE_LANG === 'en'">
                                We recommend that you read the service agreement to be clear and well informed about the terms of the service. Important points from the service agreement to consider:
                                <ul style="list-style-type:disc !important" class="space-y-4 mt-8 px-10">
                                    <li>
                                        The service is delivered with the functions specified at the time of order and the product page on the website. If there is something more that the customer wants, this requires additional work to be done and the usual hourly rate is charged for the work which results in tailor-made functions.
                                    </li>
                                    <li>
                                        The service is not a substitute for accounting staff but rather a relief tool. The customer is fully responsible for their accounting and understands that they themselves must handle any deviations and perform regular monthly reconciliations.
                                    </li>
                                    <li>
                                        At the start of the use of the application, the customer is responsible for checking that accounting data is reported in accordance with the customer's desired settings. The customer has no right to subsequently request credit if they have chosen not to use the application or incorrectly configure the application settings.
                                    </li>
                                    <li>
                                        Upon delivery, it is the customer's responsibility to check the service's functions according to the function list and promptly notify any deficiencies.
                                    </li>
                                    <li>
                                        Automateisera Mera offers free support for software related questions for subscribed integrations. If the customer requests consultation meetings, this is counted as working time and is charged at the usual rate of SEK 900 ex vat/h.
                                    </li>
                                </ul>
                            </div>
                            <div class="md:px-20 mt-10" v-else>
                                Vi rekommenderar att ni läser igenom tjänsteavtalet för att vara väl införstådda kring levererad tjänsten. Viktiga punkter från tjänsteavtalet att ha i beaktande:
                                <ul style="list-style-type:disc !important" class="space-y-4 mt-8 px-10">
                                    <li>
                                        Tjänsten levereras med de funktioner som specificeras vid ordertillfälle och produktsidan på hemsida. Om det är något mer som kunden önskar ha så är det mer tillkommet arbete och sedvanlig timtaxa utgår för arbetet vid skräddarsydda funktioner
                                    </li>
                                    <li>
                                        Tjänsten är inte ett substitut för redovisningspersonal utan snarare ett avlastnings verktyg. Kunden är fullt ansvarig för sin bokföring och förstår att de själva måste hantera eventuella avvikelser och utföra regelbundna månadsavstämningar.
                                    </li>
                                    <li>
                                        Kunden ansvarar för att vid uppstart av användningen av applikationen kontrollera att bokföringsdata rapporteras i enlighet med kundens önskade inställningar. Kunden har ingen rätt att i efterhand efterfråga kreditering om de har valt att inte nyttja applikationen eller felinställt applikationen.
                                    </li>
                                    <li>
                                        Vid leverans är det kundens ansvar att kontrollera tjänstens funktioner enligt funktionslista och skyndsamt meddela eventuella brister. 
                                    </li>
                                    <li>
                                        Automatisera Mera erbjuder fri support kring programtekniska frågor för prenumererade integrationer. Om kunden efterfrågar konsultations eller support för fel som ej är härledda från Automatisera Mera's tjänster räknas detta som debiterbar arbetstid med sedvanlig taxa av 1200kr ex moms/h.
                                    </li>
                                </ul>
                            </div>
                            <div class="mt-9 md:px-20">
                                <span>
                                    <a title="Download" href="./../../assets/pdf/Tjansteavtal.pdf" download>
                                        <img class="h-20" src="./../../assets/images/pdf-file.png" alt="">
                                    </a>
                                </span>
                            </div>
                        </div>
                    </div>
                    
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex'


    export default {
        name : 'terms-and-condition',
        data () {
            return {
                
            }
        },
        computed : {
            ...mapGetters({
                GET_LOCALE_LANG : 'utility/GET_LOCALE_LANG',
            })
        },
        mounted () {
            
        },
        methods : {
            
        }
    }
</script>

<style lang="scss" scoped>
@import "./../../assets/variables.scss";
.sticky-table{
    position: -webkit-sticky;
    position: sticky;
    top: 61px;
    background-color: #fff;
    // background-color: #e8eaec;
    height: 50px;
    padding: 7px;
}
.card-body{
    padding: 0 0.9rem !important;
}
.app-main .app-main__inner{
    padding-top: 20px !important;
}
.body-tabs-shadow .body-tabs-animated{
    padding: unset !important;
}
.tab-btn.active{
    box-shadow: unset !important;
    border: 1px solid $theme-secondary-color !important;
    font-weight: 800;
    color: $theme-secondary-color !important;
    border-radius: 5px;
}
.tab-btn{
    color: $theme-secondary-light;
}
.remove-bg{
    background: unset !important;
    background-color: unset !important;
}
</style>